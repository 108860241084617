import { SxProps, Theme } from '@mui/material';

import { ContentfulBackground, Maybe, THeroContentWidth } from '@/types';
import { resolveContentfulBgColor } from '@/utils/resolveContentfulBgColor';

import { resolveContentPositioning } from './HeroContent.helpers';

export const getWrapperStyles = ({
	contentPosition,
	backgroundColorSmallScreen,
	theme,
	smallScreenFullSizeImage,
	contentPositionSmallScreen,
}: {
	contentPosition?: Maybe<string>;
	backgroundColorSmallScreen?: Maybe<string>;
	theme: Theme;
	smallScreenFullSizeImage?: boolean;
	contentPositionSmallScreen?: Maybe<string>;
}) => {
	const isTop = contentPositionSmallScreen?.toLowerCase() === 'top';
	const smallScreenSettings = smallScreenFullSizeImage
		? {
				background: 'none',
				gridArea: '1/1',
				...(isTop ? { alignItems: 'flex-start' } : { alignItems: 'flex-end' }),
		  }
		: {
				position: 'relative',
				background: resolveContentfulBgColor(backgroundColorSmallScreen as ContentfulBackground, theme),
				gridArea: isTop ? '2/1' : '1/1',
		  };
	return {
		width: '100%',
		maxWidth: '100%',
		zIndex: 5,
		display: 'flex',
		...resolveContentPositioning(contentPosition),
		[theme.breakpoints.down('md')]: {
			padding: '5% 2%',
			height: 'auto',
			...smallScreenSettings,
		},
		[theme.breakpoints.up('md')]: {
			gridArea: '1/1',
			padding: '5% 2%',
			minHeight: 'calc(100vh - 200px)',
			position: 'relative',
			background: 'none',
		},
	};
};

export const outerWrapperStyles = (theme: Theme, contentWidth?: Maybe<THeroContentWidth>) => [
	{
		width: contentWidthPercentage(contentWidth),
		display: { md: 'flex' },
		flexDirection: { md: 'column' },
		justifyContent: { md: 'space-between' },
		height: { md: 'auto' },
	},
	{
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
];

export const contentWidthPercentage = (width: Maybe<THeroContentWidth> | undefined) => {
	switch (width) {
		case 'Small':
			return '40%';
		case 'Medium':
			return '60%';
		case 'Large':
			return '100%';
		default:
			return '60%';
	}
};

export const getInnerWrapperStyles = (contentAlign?: Maybe<string>) => ({
	width: '100%',
	height: { xs: '100%', md: 'auto' },
	paddingBottom: { xs: '0.5rem', md: '0' },
	textAlign: { xs: 'center', md: contentAlign?.toLowerCase() === 'center' ? 'center' : contentAlign || 'unset' },
	px: { xs: '1.5rem', md: 0 },
});

export const eyebrowStyles = {
	fontSize: { md: '1.125rem', xs: '1rem' },
};

export const getBodyContainerStyles = (theme: Theme, contentAlign: string, darkTextColor: boolean): SxProps => ({
	'& > ul': {
		paddingLeft: '0.3rem',
		color: darkTextColor ? 'text.secondary' : 'text.light',
		textAlign: contentAlign,
		listStylePosition: 'inside',
		listStyleType: { xs: 'none', md: 'square' },
		fontSize: { xs: '0.875rem', md: '1rem' },
	},
	'& > ul li p': {
		m: 0,
		lineHeight: '2rem',
		display: 'inline-block',
		textAlign: contentAlign,
		// HACK: list styles look pretty bad on smaller screens, so we'll just use a HTML entity bullet point instead
		[theme.breakpoints.down('md')]: {
			'&:before': {
				content: '"⦁ "',
			},
		},
	},
	marginBottom: { xs: '1rem', md: '2.75rem' },
});

export const heroHeadingStyles = { paddingLeft: { sm: 0 }, paddingBottom: { xs: '0.5rem', md: '1.25rem' } };

export const getPromoHeadlineSizeVariant = (promoHeadlineSize?: string, isSmallScreen?: boolean) => {
	if (!isSmallScreen) {
		if (promoHeadlineSize === 'Large') {
			return 'heroLarge';
		}

		return 'heroXL';
	}

	return 'heroLarge';
};

export const getHeroHeadingVariant = (headingSize?: string, isSmallScreen?: boolean) => {
	if (!isSmallScreen) {
		if (headingSize === 'Medium') {
			return 'heroMedium';
		}

		return 'heroLarge';
	}

	return 'heroMedium';
};

export const getHeroButtonWrapperStyles = (contentAlign?: Maybe<string>) => {
	return {
		display: 'flex',
		flexWrap: 'wrap',
		gap: { xs: '0.5rem', md: '1rem' },
		padding: { xs: '0 0.375rem 0.75rem', md: '0 0 1.25rem 0' },
		margin: { xs: '0 auto', md: contentAlign === 'Center' ? 'auto' : 0 },
		width: '100%',
		justifyContent: { xs: 'center', md: contentAlign?.toLowerCase() === 'center' ? 'center' : 'unset' },
	};
};

export const TACWrapperStyles = { paddingTop: { xs: '1.25rem', md: '0' }, marginBottom: 0 };
