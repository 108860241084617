import { useRef } from 'react';

import { Box, useTheme } from '@mui/material';

import {
	Asset,
	Maybe,
	HeroV2,
	HeroV2Body,
	HeroV2Heading,
	FocalPointImage,
	TermsAndConditions,
	SmallScreenContent,
	HeroV2ButtonsCollection,
	HeroV2PromoHeadline,
	DisclaimerV2,
	ImageAsset,
	VideoAsset,
} from '@/types/generated';
import Disclaimer from '@/components/Disclaimer';
import { textToJumpLink } from '@/utils/textToJumpLink/textToJumpLink';
import { THeroContentWidth } from '@/types';
import { isNullOrEmpty } from '@/utils/isNullOrEmpty/isNullOrEmpty';

import { getWrapperStyles } from './Hero.styles';
import BackgroundMedia from './BackgroundMedia';
import HeroContent from './HeroContent';

export interface IHero extends HeroV2 {
	/**
	 * Required field for the Hero id
	 *
	 * @type {string}
	 * @memberof IHero
	 */
	entryName?: Maybe<string>;
	/**
	 * Background color for small screens. This is going to be displayed behind the text content.
	 *
	 * @type {Maybe<ContentfulBackground>}
	 * @memberof IHero
	 */
	backgroundColorSmallScreen?: Maybe<string>;
	/**
	 * Body rich text content.
	 *
	 * @type {Maybe<HeroV2Body>}
	 * @memberof IHero
	 */
	body?: Maybe<HeroV2Body>;
	/**
	 * Specifies the body text size. Can be Standard or Medium.
	 *
	 * @type {Maybe<Omit<TemplateSize, "Large">>}
	 * @memberof IHero
	 */
	bodySize?: Maybe<string>;
	/**
	 * Buttons to be displayed in the hero content section.
	 *
	 * @type {Maybe<HeroV2ButtonsCollection>}
	 * @memberof IHero
	 */
	buttonsCollection?: Maybe<HeroV2ButtonsCollection>;
	/**
	 * Specifies the content alignment. Can be Left, Center or Right.
	 *
	 * @type {Maybe<TAGContentAlignment>}
	 * @memberof IHero
	 */
	contentAlign?: Maybe<string>;
	/**
	 * Specifies the position of the hero text content. Refer to resolveContentPositioning function for more details.
	 *
	 * @type {Maybe<string>}
	 * @memberof IHero
	 */
	contentPosition?: Maybe<string>;
	/**
	 * Specifies the content position for small screens. Can be Bottom or Top.
	 *
	 * @type {Maybe<string>}
	 * @memberof IHero
	 */
	contentPositionSmallScreen?: Maybe<string>;
	/**
	 * Object containing the content information for small screens.
	 *
	 * @type {Maybe<SmallScreenContent>}
	 * @memberof IHero
	 */
	contentSmallScreen?: Maybe<SmallScreenContent>;
	/**
	 * Specifies the content width at either 40% 60% or 100%.
	 *
	 * @type {Maybe<THeroContentWidth>}
	 * @memberof IHero
	 */
	contentWidth?: Maybe<THeroContentWidth>;
	/**
	 * Boolean specifying whether the background image/video should have a dark overlay.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IHero
	 */
	darkOverlay?: Maybe<boolean>;
	/**
	 * Boolean specifying the color of the text content. Hero text is going to be dark if the value is true.
	 *
	 * @type {Maybe<boolean>}
	 * @memberof IHero
	 */
	darkTextColor?: Maybe<boolean>;
	/**
	 * The disclaimer template.
	 *
	 * @type {Maybe<DisclaimerV2>}
	 * @memberof IHero
	 */
	disclaimer?: Maybe<DisclaimerV2>;
	/**
	 * Specifies the eyebrow text content.
	 *
	 * @type {Maybe<string>}
	 * @memberof IHero
	 */
	eyebrow?: Maybe<string>;
	/**
	 * Specifies the eyebrow style. Can be Standard or Promo.
	 *
	 * @type {Maybe<TAGEyebrowStyle>}
	 * @memberof IHero
	 */
	eyebrowStyle?: Maybe<string>;
	/**
	 * Background image information for the hero section.
	 *
	 * @type {Maybe<FocalPointImage>}
	 * @memberof IHero
	 */
	focalPointImage?: Maybe<FocalPointImage>;
	/**
	 * Heading rich text content.
	 *
	 * @type {Maybe<HeroV2Heading>}
	 * @memberof IHero
	 */
	heading?: Maybe<HeroV2Heading>;
	/**
	 * Specifies the font size of the heading text content. Can be Standard or Medium.
	 *
	 * @type {Maybe<Omit<TemplateSize, "Large">>}
	 * @memberof IHero
	 */
	headingSize?: Maybe<string>;
	/**
	 * Specifies the aspect ratio for background video/image on small screens.
	 *
	 * @type {Maybe<string>}
	 * @memberof IHero
	 */
	mediaAspectRatioSmallScreen?: Maybe<string>;
	/**
	 * Promotional headline information displayed above the Heading.
	 * @type {Maybe<HeroV2PromoHeadline>}
	 * @memberof IHero
	 */
	promoHeadline?: Maybe<HeroV2PromoHeadline>;
	/**
	 * Specifies the font size of the Promo Headline text content. Can be Large or XLarge.
	 * @type {Maybe<Omit<TemplateSize, "Large">>}
	 * @memberof IHero
	 */
	promoHeadlineSize?: Maybe<string>;

	/**
	 * Specifies the height of Hero. Can be Standard or Compact.
	 * @type {Maybe<Omit<TemplateSize, "Standard">>}
	 * @memberof IHero
	 */
	heightStyle?: Maybe<string>;
	/**
	 * Specifies the width of the template. Can be Full or Inset.
	 *
	 * @type {Maybe<TemplateWidth>}
	 * @memberof IHero
	 */
	templateWidth?: Maybe<string>;
	/**
	 * Terms and conditions information.
	 *
	 * @type {Maybe<TermsAndConditions>}
	 * @memberof IHero
	 */
	termsConditions?: Maybe<TermsAndConditions>;
	/**
	 * Video background information.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IHero
	 */
	video?: Maybe<Asset>;
	/**
	 * Brandfolder Image with focal point.
	 *
	 * @type {Maybe<ImageAsset>}
	 * @memberof IHero
	 */
	imageAsset?: Maybe<ImageAsset>;
	/**
	 * Video Asset with video for Desktop and Mobile
	 *
	 * @type {Maybe<VideoAsset>}
	 * @memberof IHero
	 */
	videoAsset?: Maybe<VideoAsset>;
}

export default function Hero({
	entryName,
	backgroundColorSmallScreen,
	body,
	bodySize,
	buttonsCollection,
	contentAlign,
	contentPosition,
	contentPositionSmallScreen,
	contentSmallScreen,
	contentWidth = 'Medium',
	darkOverlay,
	darkTextColor,
	disclaimer,
	eyebrow,
	eyebrowStyle,
	focalPointImage,
	heading,
	headingSize,
	mediaAspectRatioSmallScreen,
	promoHeadline,
	promoHeadlineSize,
	templateWidth,
	termsConditions,
	video,
	heightStyle,
	imageAsset,
	videoAsset,
}: IHero) {
	const heroContentRef = useRef<HTMLDivElement>(null);
	const theme = useTheme();
	const isCompactMode = heightStyle === 'Compact';

	const idLabel = textToJumpLink(entryName);

	const isNoContent = [eyebrow, heading, promoHeadline, body, buttonsCollection?.items].every(isNullOrEmpty);
	// TODO: Need to revisit place holder background color for a more viable solution and for other image cases
	const placeholderBackgroundColor =
		isNoContent && videoAsset
			? 'transparent'
			: {
					xs: 'backgrounds.neutral',
					md: darkTextColor ? 'backgrounds.neutral' : 'backgrounds.dark',
			  };
	// Portrait (9:16) on small screen have background image
	const smallScreenFullSizeImage = !video && !videoAsset && !!mediaAspectRatioSmallScreen?.includes('9:16');
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				...(isNoContent && videoAsset
					? { height: { md: 'calc(100vh - 200px)' }, display: { xs: 'none', md: 'flex' } }
					: {}),
			}}
		>
			<Box
				component="section"
				data-test-id="section_hero"
				sx={getWrapperStyles({
					theme,
					isCompactMode,
					templateWidth,
					darkOverlay,
				})}
				id={`hero-${idLabel}`}
			>
				<BackgroundMedia
					contentPosition={contentPosition}
					contentSmallScreen={contentSmallScreen}
					focalPointImage={focalPointImage}
					mediaAspectRatioSmallScreen={mediaAspectRatioSmallScreen}
					smallScreenFullSizeImage={smallScreenFullSizeImage}
					video={video}
					contentHeight={0}
					placeholderColor={placeholderBackgroundColor}
					imageAsset={imageAsset}
					videoAsset={videoAsset}
					fromHero={true}
				/>

				<HeroContent
					backgroundColorSmallScreen={backgroundColorSmallScreen}
					body={body}
					bodySize={bodySize}
					buttons={buttonsCollection?.items}
					contentAlign={contentAlign}
					contentPosition={contentPosition}
					contentSmallScreen={contentSmallScreen}
					contentWidth={contentWidth}
					darkTextColor={darkTextColor}
					eyebrow={eyebrow}
					eyebrowStyle={eyebrowStyle}
					hasVideoBackground={Boolean(video || contentSmallScreen?.videoSmallScreen)}
					heading={heading}
					headingSize={headingSize}
					mediaAspectRatioSmallScreen={mediaAspectRatioSmallScreen}
					contentPositionSmallScreen={contentPositionSmallScreen}
					smallScreenFullSizeImage={smallScreenFullSizeImage}
					promoHeadline={promoHeadline}
					promoHeadlineSize={promoHeadlineSize}
					termsConditions={termsConditions}
					ref={heroContentRef}
					isNoContent={isNoContent}
				></HeroContent>
			</Box>
			{disclaimer && <Disclaimer disclaimer={disclaimer.disclaimer} dataTestId="hero_disclaimer" />}
		</Box>
	);
}
