import {
	Box,
	Container,
	SvgIconProps,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	TypographyProps,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';

import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import { ContentfulButtonVariant } from '@/types';
import { resolveContentfulButtonVariant } from '@/utils';

import TAGLink from '../TAGLink';
import ImageWrapper from '../ImageWrapper';
import TAGButton from '../TAGButton/TAGButton';

import {
	getImageStyles,
	tableFromJsonCellStyles,
	tableFromJsonCtrStyles,
	tableFromJsonRowStyles,
	tableFromJsonStyles,
} from './Table.styles';

export interface ITableCellContentStyleProps {
	bgColor: string;
	textVariant: string;
	eyebrowColor: string;
	alignment: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined;
	verticalAlignment: string;
	padding: string;
	border: string;
}
interface ITableCellText {
	value: string;
	eyebrow: string;
}
interface ITableCellImage {
	url: string;
}
interface ITableCellIcon {
	name: IconName;
	color: string;
}

interface ITableCellButton {
	value: string;
	variant: string;
	link: string;
}
interface ITableCellLink {
	url: string;
	openInNewTab: boolean;
	value: string;
}

interface ITableCell {
	image: ITableCellImage;
	text: ITableCellText;
	icon: ITableCellIcon;
	link: ITableCellLink;
	button: ITableCellButton;
	styles: ITableCellContentStyleProps;
}

interface ITableRow {
	cells: Array<ITableCell>;
}

export interface ITableFromJson {
	rows: Array<ITableRow>;
}
export interface ITableFromJsonProps {
	jsonData?: ITableFromJson;
	firstColumnSticky?: boolean | null; 
}

const TableCellContent = (props: { cells: Array<ITableCell>; isSmallScreen: boolean; rowIndex: number }) => {
	const { cells, isSmallScreen, rowIndex } = props;
	return (
		<>
			{cells.map((cell: ITableCell, index: number) => (
				<Box
					align={cell.styles.alignment || 'center'}
					key={`table_cell_${rowIndex}_${index}`}
					data-test-id={`table_from_json_table_cell_${rowIndex}_${index}`}
					sx={tableFromJsonCellStyles(cell.styles)}
					padding="none"
					component={TableCell}
				>
					{cell.icon?.name && (
						<TAGSvgIcon
							icon={cell.icon?.name}
							htmlColor={cell.icon?.color as SvgIconProps['htmlColor']}
							data-test-id={`table_from_json_table_cell_icon_${rowIndex}_${index}`}
						/>
					)}
					{cell.text?.eyebrow && (
						<Typography
							variant={isSmallScreen ? 'bodyMediumBold' : 'bodyLargeBold'}
							color={cell.styles?.eyebrowColor || 'success.dark'}
							data-test-id={`table_from_json_table_cell_text_eyebrow_${rowIndex}_${index}`}
						>
							{cell.text.eyebrow}
						</Typography>
					)}
					{cell.text?.value && (
						<Typography
							variant={cell.styles.textVariant as TypographyProps['variant']}
							data-test-id={`table_from_json_table_cell_text_value_${rowIndex}_${index}`}
						>
							<ReactMarkdown>{cell.text.value}</ReactMarkdown>
						</Typography>
					)}
					{cell.image?.url && (
						<ImageWrapper
							src={cell.image.url}
							sx={getImageStyles}
							data-test-id={`table_from_json_table_cell_image_${rowIndex}_${index}`}
						/>
					)}
					{cell.link?.url && (
						<TAGLink
							href={cell.link.url}
							openInNewTab={cell.link.openInNewTab}
							data-test-id={`table_from_json_table_cell_link_${rowIndex}_${index}`}
						>
							<ReactMarkdown>{cell.link.value}</ReactMarkdown>
						</TAGLink>
					)}
					{cell.button?.link && (
						<TAGButton
							size={isSmallScreen ? 'S' : 'M'}
							variant={resolveContentfulButtonVariant(
								cell.button.variant as ContentfulButtonVariant,
								'secondaryDefault'
							)}
							dataTestId={`table_from_json_table_cell_button_${rowIndex}_${index}`}
							href={cell.button?.link}
						>
							<ReactMarkdown>{cell.button.value}</ReactMarkdown>
						</TAGButton>
					)}
				</Box>
			))}
		</>
	);
};

const TableRowContent = (props: { row: ITableRow; isSmallScreen: boolean; index: number; firstColumnSticky: boolean | null }) => {
	const { row, isSmallScreen, index, firstColumnSticky = true } = props;
	return (
		<Box
			key={`table_from_json_${row.cells?.length}_${index}`}
			data-test-id={`table_from_json_table_row_${index}`}
			component={TableRow}
			sx={tableFromJsonRowStyles(firstColumnSticky)}
		>
			<TableCellContent cells={row?.cells} isSmallScreen={isSmallScreen} rowIndex={index} />
		</Box>
	);
};

export default function TableFromJson({ jsonData, firstColumnSticky = true }: Readonly<ITableFromJsonProps>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (!jsonData) return null;

	const { rows } = jsonData;

	return (
		<Container
			component={Table}
			disableGutters
			maxWidth={false}
			sx={tableFromJsonCtrStyles}
			data-test-id="table_from_json_table"
		>
			<Box sx={tableFromJsonStyles} data-test-id="table_from_json_table_body" component={TableBody}>
				{rows.map((row: ITableRow, index: number) => (
					<TableRowContent
						row={row}
						isSmallScreen={isSmallScreen}
						index={index}
						key={`table_from_json_${row.cells.length}_${index}`}
						firstColumnSticky={firstColumnSticky}
					/>
				))}
			</Box>
		</Container>
	);
}
