import { SxProps, Theme } from '@mui/material';

import { Maybe } from '@/types';

export const getWrapperStyles = ({
	theme,
	isCompactMode,
	templateWidth,
	darkOverlay,
}: {
	theme: Theme;
	isCompactMode: boolean;
	templateWidth?: Maybe<string>;
	darkOverlay?: Maybe<boolean>;
}): SxProps => {
	return {
		position: 'relative',
		display: 'grid',
		maxWidth: '100%',
		width: '100%',
		margin: '0 auto',
		gridTemplateColumns: '100%',
		gridTemplateRows: 'auto auto',
		[theme.breakpoints.up('md')]: {
			minHeight: isCompactMode ? 'calc(100vh - 12.5rem)' : '',
			flexDirection: 'column',
			width: templateWidth === 'Inset' ? '90%' : '100%',
			gridTemplateColumns: '100%',
			gridTemplateRows: 'auto',
		},
		[theme.breakpoints.down('md')]: {
			paddingLeft: '0',
			paddingRight: '0',
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: isCompactMode ? 'calc(100vh - 5rem)' : '',
		},
		'&:after': {
			content: '""',
			width: '100%',
			backgroundImage: {
				md: darkOverlay ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' : '',
			},
			position: 'absolute',
			right: 0,
			top: 0,
			bottom: 0,
			zIndex: 1,
		},
	};
};
